html {
  height: 100%;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
